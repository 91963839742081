import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { useAuth0 } from "@utils/auth"
import Table from "@elements/Table"
import Block from "@global/layout/Block"
import fromUnixTime from "date-fns/fromUnixTime"
import { formatDate } from "@helpers/dateHelper"
import { getPortalSession } from "@helpers/userHelpers"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Spinner from "@global/layout/Spinner"
import Button from "@elements/Button"
import Input from "@elements/Input"
import Content from "@elements/Content"

const Profile = ({
  data: { site, plans, seo, content },
  pageContext: { parent },
  location
}) => {
  const {
    loading,
    user,
    subscriptions,
    hasActiveSubscriptions,
    hasPausedPlan
  } = useAuth0()
  const [redirecting, setRedirecting] = useState(null)

  const { sections, siteUrl } = site.siteMetadata
  const isCustomer = user

  return (
    <Layout parent={parent} title={"My Profile"}>
      {loading ? (
        <Spinner />
      ) : (
        <ProtectedRoute>
          <HelmetDatoCms
            defer={false}
            title={`My Profile${seo.globalSeo.titleSuffix}`}
          />
          <>
            <Block padding="top" gutters={true} maxWidth="inner">
              <Content html={`<h1>${sections.profile.title}</h1>`} />
            </Block>
            {subscriptions.length === 0 && (
              <Block gutters={true} maxWidth="inner">
                <Content type="warning" html={content.profile.message} />
              </Block>
            )}
            <Block padding="top" gutters={true} maxWidth="inner">
              <h2>Details</h2>
              {user && (
                <Input
                  name={"name"}
                  label="Name"
                  value={user.name}
                  disabled={true}
                />
              )}
              {user && (
                <Input
                  name={"email"}
                  label="Email"
                  value={user.email}
                  disabled={true}
                />
              )}
            </Block>
            <Block padding="top" gutters={true} maxWidth="inner">
              <h2>Subscriptions</h2>
              {subscriptions && <SubscriptionProfile plans={plans.nodes} />}
              {isCustomer && !hasPausedPlan && (
                <Block padding="both">
                  <Button
                    type={"button"}
                    onClick={() => {
                      const { customer } = user[
                        `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`
                      ]
                      getPortalSession(
                        customer.id,
                        siteUrl + sections.profile.base
                      )
                      setRedirecting(true)
                    }}
                    text={!redirecting ? "Edit My Plans" : <Spinner auto />}
                  />
                </Block>
              )}
            </Block>

            {!hasActiveSubscriptions && !hasPausedPlan && (
              <Block gutters={true} padding="bottom">
                <Button text="Subscribe" to="/subscribe" />
              </Block>
            )}
            {hasPausedPlan && (
              <Block gutters={true} padding="top">
                <Button text="Resume Subscription" to="/contact-kate" />
              </Block>
            )}
          </>
        </ProtectedRoute>
      )}
    </Layout>
  )
}

export default Profile

const SubscriptionProfile = ({ plans }) => {
  const { subscriptions } = useAuth0()

  if (!subscriptions) return null

  const tableRows = subscriptions.map(item => {
    const { plan_id, plan_unit_price, status, next_billing_at } = item
    const planStatus = status === "in_trial" ? "trial" : status
    const planObj = plans.find(
      plan => plan.plan && plan.plan.alternative_id === plan_id
    )
    const date = next_billing_at
      ? formatDate(fromUnixTime(next_billing_at))
      : "n/a"
    let planName = ""
    if (planObj) {
      planName = planObj.plan.name
    }
    const cost = `$${plan_unit_price / 100}`

    return [planName, planStatus, cost, date]
  })

  return (
    <Table
      head={["Plan", "Status", "Cost", "Next bill date"]}
      body={tableRows}
      modifier="full-width"
    />
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        sections {
          profile {
            title
            base
            slug
          }
        }
        siteUrl
      }
    }
    plans: allChargebeePlans {
      nodes {
        plan {
          price
          name
          alternative_id
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      profile {
        message
      }
    }
  }
`
